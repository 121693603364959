<template>
  <div>
    <ErrorPage v-if="showError" />
    <div v-else>
      <loader v-if="isLoading" size="xxs" :loader-image="false" />
      <div v-else class="flex flex-col gap-4 w-full">
        <c-card class="w-full flex flex-col p-5">
          <CardFooter
            class="-mt-5"
            printcard
            reloadcard
            :show-search="false"
            @reload="
              mode === 'admin'
                ? getYearEndBalance()
                : getManagerYearEndBalance('')
            "
            @sortType="
              $queryBuilder(
                { sort: $event },
                mode === 'admin' ? getYearEndBalance : getManagerYearEndBalance
              )
            "
            @searchResult="
              $queryBuilder(
                { search: $event },
                mode === 'admin' ? getYearEndBalance : getManagerYearEndBalance
              )
            "
          />
        </c-card>

        <c-table
          :headers="headers"
          :items="balances"
          aria-label="year end leave table"
          :loading="isFetching"
          :has-number="false"
          :page-sync="true"
          :pagination-list="pagination"
          @page="
            $queryBuilder(
              { page: $event },
              mode === 'admin' ? getYearEndBalance : getManagerYearEndBalance
            )
          "
          @itemsPerPage="
            $queryBuilder(
              { perPage: $event },
              mode === 'admin' ? getYearEndBalance : getManagerYearEndBalance
            )
          "
          v-if="balances.length || isFetching"
        >
          <template v-slot:item="{ item }">
            <span v-if="item.year" class="text-darkPurple text-sm font-normal">
              {{ item.data.year }}
            </span>
            <span
              v-if="item.numberOfEmployees"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.numberOfEmployees }}
            </span>
            <span
              v-if="item.entitlement"
              class="text-darkPurple text-sm font-normal"
              >{{ item.data.entitlement }}</span
            >
            <span
              v-if="item.maximumLeaveAllowance"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.maximumLeaveAllowance }}
            </span>
            <span
              v-if="item.unusedDaysInPreviousYearSum"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.unusedDaysInPreviousYearSum }}
            </span>
            <div
              v-if="item.totalLeaveOutstandingAmount"
              class="flex flex-col justify-start gap-1"
            >
              <span class="text-darkPurple text-sm font-semibold">
                {{ convertToCurrency(item.data.totalLeaveOutstandingAmount) }}
              </span>
              <span class="text-carrotOrange text-sm"
                >({{ item.data.numberOfEmployees }} Employees)</span
              >
            </div>
            <div v-if="item.id" class="cursor-pointer">
              <div @click="checkPrivileges(item.data.id)">
                <c-icon icon-name="icon-eye" size="xs" />
              </div>
            </div>
          </template>
        </c-table>

        <div
          v-else
          class="flex flex-col justify-center items-center gap-0 px-10"
        >
          <Icon
            icon-name="paygrade_Illustration"
            size=""
            class-name="w-56 h-56"
          />
          <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
            Year end leave balances can be found here. This is empty now, but
            you can start adding year end leave balances to populate this area.
          </p>
        </div>

        <FilterTable
          v-if="isOpenFilter"
          :filter-data="filterOptions"
          @close="isOpenFilter = false"
          @submit="isOpenFilter = false"
        />

        <ViewDaysBackRequest ref="viewDaysBackRequestRef" />
        <AddLeaveRequest ref="leaveRequestRef" />
        <ViewLeaveRequest ref="viewRequestRef" />
        <GetLeaveBackRequest ref="getLeaveBackRef" />
      </div>
    </div>
  </div>
</template>

<script>
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import CIcon from "@/components/Icon";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    CCard,
    CTable,
    CardFooter,
    FilterTable,
    CIcon,
    ErrorPage,
    ViewDaysBackRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/ViewDaysBackRequest"
      ),
    AddLeaveRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/AddLeaveRequest"
      ),
    ViewLeaveRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/ViewLeaveRequest"
      ),
    GetLeaveBackRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/GetLeaveBack"
      ),
  },
  data() {
    return {
      isOpenFilter: false,
      showError: false,
      isLoading: false,
      isFetching: false,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      pagination: {},
      summary: {
        totalmaximumApplicableDays: 0,
        daysUsed: 0,
        leaveBalance: 0,
      },
      balances: [],
      headers: [
        { title: "Leave Year", value: "year" },
        { title: "Total No of Employees", value: "numberOfEmployees" },
        {
          title: "Total Leave Entitlement (Days)",
          value: "maximumLeaveAllowance",
        },
        { title: "Leave Balance (Days)", value: "unusedDaysInPreviousYearSum" },
        {
          title: "Total Leave Allowance Outstanding (NGN)",
          value: "totalLeaveOutstandingAmount",
        },
        { title: "", value: "id", image: true },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  methods: {
    async checkPrivileges(id) {
        if (this.mode === 'admin') {
          try {
               await this.$handlePrivilege("yearEndLeaveBalance", "viewYearEndBalance");
            this.$router.push({
              name: `${
                this.mode === "admin"
                  ? "ViewYearEndLeaveBalance"
                  : "EssViewYearEndLeaveBalance"
              }`,
              params: { id },
              query: { slug: this.mode },
            });
          } catch (error) {
            this.$toasted.error("You do not have permission to perform this task", {
              duration: 5000,
            });
          }
        }else {
            this.$router.push({
              name: `${
                this.mode === "admin"
                  ? "ViewYearEndLeaveBalance"
                  : "EssViewYearEndLeaveBalance"
              }`,
              params: { id },
              query: { slug: this.mode },
            });
        }
    },
    async getYearEndBalance(params) {
      this.isFetching = true;
      await this.$_orgYearEndLeaveBalance(params).then(({ data }) => {
        this.balances = data.balances?.map((i) => ({ ...i, id: i.year }));
        this.pagination = data.meta;
        this.isFetching = false;
      });
    },
    async getManagerYearEndBalance(params) {
      this.isFetching = true;
      await this.$_ManagerEmployeesYearEndLeaveBalance(params).then(
        ({ data }) => {
          this.balances = data.balances?.map((i) => ({ ...i, id: i.year }));
          this.pagination = data.meta;
          this.isFetching = false;
        }
      );
    },
  },
  async created() {
    if(this.mode === 'admin' ) {
    this.isLoading = true
      try {
        await this.$handlePrivilege("yearEndLeaveBalance", "viewRegister");
        await this.getYearEndBalance();
        this.showError = false;
        this.isLoading = false;
      } catch (error) {
        this.showError = true;
        this.isLoading = false;
      }
    }
    else {
        await this.getManagerYearEndBalance("");
    }
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
