<template>
  <div>
    <ErrorPage v-if="showError" />
    <div v-else class="flex flex-col gap-4 w-full">
      <c-card class="w-full flex flex-col p-5 pt-0">
        <CardFooter
          printcard
          reloadcard
          :show-search="false"
          @reload="
            mode === 'admin'
              ? getEmployeeLeavePlan('')
              : getManagersLeavePlan('')
          "
          @sortType="
            $queryBuilder(
              { sort: $event },
              mode === 'admin' ? getEmployeeLeavePlan : getManagersLeavePlan
            )
          "
          @searchResult="
            $queryBuilder(
              { search: $event },
              mode === 'admin' ? getEmployeeLeavePlan : getManagersLeavePlan
            )
          "
        />
      </c-card>

      <c-table
        :headers="headers"
        :items="leavePlanOptions"
        aria-label="annual leave plan table"
        :loading="isFetching"
        :has-number="false"
        :page-sync="true"
        :pagination-list="pagination"
        @page="
          $queryBuilder(
            { page: $event },
            mode === 'admin' ? getEmployeeLeavePlan : getManagersLeavePlan
          )
        "
        @itemsPerPage="
          $queryBuilder(
            { perPage: $event },
            mode === 'admin' ? getEmployeeLeavePlan : getManagersLeavePlan
          )
        "
        v-if="isFetching || leavePlanOptions.length"
      >
        <template v-slot:item="{ item }">
          <span
            v-if="item.leaveYear"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.leaveYear }}
          </span>
          <span
            v-if="item.numberOfEmployees"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.numberOfEmployees }}
          </span>
          <span
            v-if="item.totalEntitlementDays"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.totalEntitlementDays }}
          </span>
          <span
            v-if="item.totalPlannedLeaveDays"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.totalPlannedLeaveDays }}
          </span>
          <span
            v-if="item.totalUnplannedLeaveDays"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.totalUnplannedLeaveDays }}
          </span>
          <div v-if="item.leavePlanId" class="cursor-pointer">
            <div @click="viewLeavePlan(item.data.leavePlanId)">
              <c-icon icon-name="icon-eye" size="xs" />
            </div>
          </div>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <Icon
          icon-name="paygrade_Illustration"
          size=""
          class-name="w-56 h-56"
        />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Leave plans can be found here. This is empty now, but you can start
          adding leave plans to populate this area.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CIcon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    CCard,
    CIcon,
    CardFooter,
    ErrorPage,
    CTable,
  },
  data() {
    return {
      isOpenFilter: false,
      showError: false,
      isFetching: false,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      pagination: {},
      leavePlanOptions: [],
      headers: [
        { title: "Leave Year", value: "leaveYear" },
        { title: "Total No of Employees", value: "numberOfEmployees" },
        {
          title: "Total Leave Entitlement (Days)",
          value: "totalEntitlementDays",
        },
        { title: "Planned Leave (Days)", value: "totalPlannedLeaveDays" },
        { title: "Unplanned Leave (Days)", value: "totalUnplannedLeaveDays" },
        { title: "", value: "leavePlanId", image: true },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  methods: {
    async viewLeavePlan(id) {
      try {
        if (this.mode === "admin") {
          await this.$handlePrivilege("leavePlan", "viewLeavePlan");
        }
        this.$router.push({
          name: `${
            this.mode === "admin"
              ? "ViewAdminAnnualLeavePlan"
              : "EssViewAdminAnnualLeavePlan"
          }`,
          params: { leaveYear: id },
          query: { slug: this.mode },
        });
      } catch (error) {
        return this.$toasted.error(
          "You do not have permission to perform this task",
          {
            duration: 5000,
          }
        );
      }
    },
    getEmployeeLeavePlan(params) {
      this.isFetching = true;
      this.$_getAdminAnnualPlans(params)
        .then(({ data }) => {
          this.pagination = data.meta;
          this.leavePlanOptions = data.data.map((item) => ({
            ...item,
            leavePlanId: item.leaveYear,
          }));
          this.isFetching = false;
        })
        .catch(() => {
          this.$toasted.error(
            "Error fetching data, please check your network.",
            { duration: 3000 }
          );
          this.isFetching = false;
        });
    },
    getManagersLeavePlan(params) {
      this.isFetching = true;
      this.$_getManagerAnnualPlans(params)
        .then(({ data }) => {
          this.pagination = data.meta;
          this.leavePlanOptions = data.data.map((item) => ({
            ...item,
            leavePlanId: item.leaveYear,
          }));
          this.isFetching = false;
        })
        .catch((error) => {
          const message = "Something went wrong, Try again";
          this.$toasted.error(error || message, { duration: 3000 });
          this.isFetching = false;
        });
    },
    async bootstrapModule() {
      if (this.mode === "admin") {
        try {
          await this.$handlePrivilege("leavePlan", "viewRegister");
          this.getEmployeeLeavePlan();
          this.showError = false;
        } catch (error) {
          this.showError = true;
        }
      } else {
        this.showError = false;
        this.getManagersLeavePlan("");
      }
    },
  },
  async created() {
    await this.bootstrapModule();
  },
};
</script>
