var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showError)?_c('ErrorPage'):_c('div',{staticClass:"flex flex-col gap-4 w-full"},[_c('c-card',{staticClass:"w-full flex flex-col p-5 pt-0"},[_c('CardFooter',{attrs:{"printcard":"","reloadcard":"","show-search":false},on:{"reload":function($event){_vm.mode === 'admin'
            ? _vm.getEmployeeLeavePlan('')
            : _vm.getManagersLeavePlan('')},"sortType":function($event){return _vm.$queryBuilder(
            { sort: $event },
            _vm.mode === 'admin' ? _vm.getEmployeeLeavePlan : _vm.getManagersLeavePlan
          )},"searchResult":function($event){return _vm.$queryBuilder(
            { search: $event },
            _vm.mode === 'admin' ? _vm.getEmployeeLeavePlan : _vm.getManagersLeavePlan
          )}}})],1),(_vm.isFetching || _vm.leavePlanOptions.length)?_c('c-table',{attrs:{"headers":_vm.headers,"items":_vm.leavePlanOptions,"aria-label":"annual leave plan table","loading":_vm.isFetching,"has-number":false,"page-sync":true,"pagination-list":_vm.pagination},on:{"page":function($event){return _vm.$queryBuilder(
          { page: $event },
          _vm.mode === 'admin' ? _vm.getEmployeeLeavePlan : _vm.getManagersLeavePlan
        )},"itemsPerPage":function($event){return _vm.$queryBuilder(
          { perPage: $event },
          _vm.mode === 'admin' ? _vm.getEmployeeLeavePlan : _vm.getManagersLeavePlan
        )}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item.leaveYear)?_c('span',{staticClass:"text-darkPurple text-sm font-normal"},[_vm._v(" "+_vm._s(item.data.leaveYear)+" ")]):_vm._e(),(item.numberOfEmployees)?_c('span',{staticClass:"text-darkPurple text-sm font-normal"},[_vm._v(" "+_vm._s(item.data.numberOfEmployees)+" ")]):_vm._e(),(item.totalEntitlementDays)?_c('span',{staticClass:"text-darkPurple text-sm font-normal"},[_vm._v(" "+_vm._s(item.data.totalEntitlementDays)+" ")]):_vm._e(),(item.totalPlannedLeaveDays)?_c('span',{staticClass:"text-darkPurple text-sm font-normal"},[_vm._v(" "+_vm._s(item.data.totalPlannedLeaveDays)+" ")]):_vm._e(),(item.totalUnplannedLeaveDays)?_c('span',{staticClass:"text-darkPurple text-sm font-normal"},[_vm._v(" "+_vm._s(item.data.totalUnplannedLeaveDays)+" ")]):_vm._e(),(item.leavePlanId)?_c('div',{staticClass:"cursor-pointer"},[_c('div',{on:{"click":function($event){return _vm.viewLeavePlan(item.data.leavePlanId)}}},[_c('c-icon',{attrs:{"icon-name":"icon-eye","size":"xs"}})],1)]):_vm._e()]}}],null,false,4041456607)}):_c('div',{staticClass:"flex flex-col justify-center items-center gap-0 px-10"},[_c('Icon',{attrs:{"icon-name":"paygrade_Illustration","size":"","class-name":"w-56 h-56"}}),_c('p',{staticClass:"font-normal text-base leading-5 text-jet text-center w-1/2"},[_vm._v(" Leave plans can be found here. This is empty now, but you can start adding leave plans to populate this area. ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }